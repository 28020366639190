import React, { useState } from 'react';
import { signUpWithEmail, loginWithEmail, googleSignIn, sendPasswordReset } from './AuthService';  // Import password reset function

const AuthComponent = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resetEmail, setResetEmail] = useState(''); // Email for resetting password
  const [showReset, setShowReset] = useState(false); // Toggle for showing reset form

  const handleSignUp = async () => {
    try {
      await signUpWithEmail(email, password);
      alert('Sign-up successful!');
    } catch (error) {
      console.error('Sign-up error:', error.message);
      alert(error.message);
    }
  };

  const handleLogin = async () => {
    try {
      await loginWithEmail(email, password);
      alert('Login successful!');
    } catch (error) {
      console.error('Login error:', error.message);
      alert(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
      alert('Google sign-in successful!');
    } catch (error) {
      console.error('Google sign-in error:', error.message);
      alert(error.message);
    }
  };

  const handlePasswordReset = async () => {
    try {
      await sendPasswordReset(resetEmail);  // Trigger password reset email
      alert('Password reset email sent! Please check your inbox.');
      setShowReset(false);  // Hide the reset form after sending the email
    } catch (error) {
      console.error('Password reset error:', error.message);
      alert(error.message);
    }
  };

  return (
    <div >
      <h3>Welcome to TaleHatch, where your child’s imagination runs wild and their creativity knows no limits! With TaleHatch, your little one can create one-of-a-kind stories, bringing their very own characters and worlds to life. If they fall in love with their creation, they can save it to their favorites and relive the adventure anytime they want. And here’s the best part—you can turn their story into a beautiful, keepsake book or even order a custom plushie of their main character! TaleHatch makes storytelling an unforgettable experience that you and your child will treasure forever.</h3>
      <h2 >Sign Up / Login</h2>

      {showReset ? (
        <>
          {/* Password reset form */}
          <input
            type="email"
            placeholder="Enter your email for reset"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}

          />
          <button onClick={handlePasswordReset}>
            Send Password Reset Email
          </button>
          <button onClick={() => setShowReset(false)} >
            Back to Login
          </button>
        </>
      ) : (
        <>
          {/* Sign Up / Login form */}
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={styles.input}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={styles.input}
          />

          <div >
            <button onClick={handleSignUp}>Sign Up</button>
            <button onClick={handleLogin} >Login</button>
          </div>

          <button onClick={handleGoogleSignIn} >
            Sign in with Google
          </button>

          {/* "Forgot Password?" link */}
          <p>
            <span
              onClick={() => setShowReset(true)}
              style={{
                color: '#007bff',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
            >
              Forgot Password?
            </span>
          </p>
        </>
      )}
    </div>
  );
};

const styles = {
  input: {
    width: '100%',
    padding: '10px',
    marginBottom: '15px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '16px',
  }
}


export default AuthComponent;
