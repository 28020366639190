import React, { useState } from "react";

const PromptInput = ({ question, addAnswer }) => {
  const [input, setInput] = useState("");

  const handleChange = (e) => setInput(e.target.value);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim()) {
      addAnswer(input.trim());
      setInput("");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="prompt-input">
      <h2>{question}</h2>
      <input
        type="text"
        value={input}
        onChange={handleChange}
        placeholder="Your answer"
      />
      <button type="submit">Next</button>
    </form>
  );
};

export default PromptInput;