import React, { useState, useEffect } from 'react';
import { getFavoriteStories, deleteFavoriteStory } from './FirestoreService'; 
import OrderForm from './OrderForm'; 

const FavoriteStories = ({ user, onSelectStory, goToStoryCreation }) => {
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedStory, setSelectedStory] = useState(null);  
  const [showOrderForm, setShowOrderForm] = useState(false); 

  useEffect(() => {
    const loadFavorites = async () => {
      try {
        const favoriteStories = await getFavoriteStories(user);
        setFavorites(favoriteStories);
      } catch (error) {
        console.error('Error loading favorite stories:', error);
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      loadFavorites();
    }
  }, [user]);

  
  const handleDelete = async (storyId) => {
    try {
      await deleteFavoriteStory(user.uid, storyId);  
      setFavorites(favorites.filter((story) => story.id !== storyId)); 
      setSelectedStory(null);  
    } catch (error) {
      console.error('Error deleting story:', error);
      alert('Failed to delete the story.');
    }
  };

 
  const handleOrderClick = () => {
    setShowOrderForm(!showOrderForm);
  };

  if (loading) {
    return <p style={{ fontSize: '1.5em', color: '#ff69b4' }}>Loading favorites...</p>;
  }

  if (favorites.length === 0) {
    return <p style={{ fontSize: '1.5em', color: '#ff69b4' }}>No favorites saved yet.</p>;
  }

  const renderStory = (story) => (
    <div className="full-story" style={fullStoryStyle}>
      <h2>{story.name}</h2> 
      <p>{story.story}</p> 
      {story.imageUrl && (
        <div className="character-image">
          <img src={story.imageUrl} alt="Main Character" className="story-image" style={storyImageStyle} />
        </div>
      )}
      <div className="story-controls" style={controlsContainerStyle}>
        <button onClick={() => setSelectedStory(null)} >
          Back to Favorites
        </button>
        <button onClick={goToStoryCreation} >
          Create a New Story
        </button>
  
        <button onClick={handleOrderClick} >
          {showOrderForm ? 'Hide Order Form' : 'Order Story or Plushie'}
        </button>
        
        <button
          onClick={() => handleDelete(story.id)}
          
        >
          Delete Story
        </button>
      </div>

      {showOrderForm && (
        <OrderForm
          storyTitle={story.name}         
          imageUrl={story.imageUrl}       
          storyContent={story.story}      
        />
      )}
    </div>
  );

  // const buttonStyle = {
  //   background: 'linear-gradient(135deg, #ff69b4, #ba55d3)',
  //   color: 'white',
  //   border: 'none',
  //   borderRadius: '10px',
  //   padding: '15px 30px',
  //   fontSize: '1.2em',
  //   cursor: 'pointer',
  //   transition: 'background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease',
  //   margin: '10px',
  //   boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)',
  // };

  const storyItemStyle = {
    margin: '15px 0',
    padding: '15px',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '15px',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  };

  const fullStoryStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    padding: '30px',
    borderRadius: '20px',
    boxShadow: '0 10px 40px rgba(0, 0, 0, 0.15)',
    marginTop: '30px',
  };

  const storyImageStyle = {
    maxWidth: '100%',
    borderRadius: '15px',
    marginTop: '20px',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)',
  };

  const controlsContainerStyle = {
    marginTop: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  };

  return (
    <div >
      {selectedStory ? (
        renderStory(selectedStory)  
      ) : (
        <>
          <h2 style={{ fontSize: '2em', color: '#ff69b4' }}>Your Favorite Stories</h2>
          <ul style={{ listStyleType: 'none'}}>
            {favorites.map((favorite) => (
              <li
                key={favorite.id}
                style={storyItemStyle}
                onMouseOver={(e) => {
                  e.currentTarget.style.transform = 'scale(1.05)';
                  e.currentTarget.style.boxShadow = '0 10px 20px rgba(0, 0, 0, 0.2)';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.transform = 'scale(1)';
                  e.currentTarget.style.boxShadow = '0 5px 15px rgba(0, 0, 0, 0.1)';
                }}
              >
                <button
                  onClick={() => setSelectedStory(favorite)} 
                  
                >
                  {favorite.name}
                </button>
              </li>
            ))}
          </ul>
          <button onClick={goToStoryCreation} >
            Create a New Story
          </button>
        </>
      )}
    </div>
  );
};

export default FavoriteStories;
