// src/AuthService.js
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, signInWithPopup, GoogleAuthProvider, sendPasswordResetEmail } from "firebase/auth";
import { app } from './FirebaseConfig'; // Import the initialized Firebase app

const auth = getAuth(app);

export const signUpWithEmail = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
};

export const loginWithEmail = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
};

export const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
};

export const logout = () => {
    return signOut(auth);
};

export const getCurrentUser = () => {
    return auth.currentUser;
};

/**
 * Sends a password reset email to the specified user.
 * @param {string} email - The email address of the user who forgot their password.
 * @returns {Promise<void>}
 */
export const sendPasswordReset = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      console.log('Password reset email sent successfully.');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      throw error;
    }
  };