// FirebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"; // Import Firestore
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB-pYVt2EvKyLyhwoBNxJTChPuxu0ihPbA",
  authDomain: "story-generator-70682.firebaseapp.com",
  projectId: "story-generator-70682",
  storageBucket: "story-generator-70682.appspot.com",
  messagingSenderId: "252472297308",
  appId: "1:252472297308:web:c190f85f6f4ca09295a399",
  measurementId: "G-MTEKEDTDXF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app); // Initialize Firestore

export { app, auth, analytics, db };