// FirestoreService.js
import { db } from './FirebaseConfig'; // Import the Firestore config
import { collection, addDoc, getDocs, query, where, doc, deleteDoc } from 'firebase/firestore';

// Save favorite story to Firestore
export const saveFavoriteStory = async (user, story, imageUrl, storyName) => {
  try {
    const favoritesRef = collection(db, 'users', user.uid, 'favorites');
    await addDoc(favoritesRef, {
      name: storyName,
      story: story,
      imageUrl: imageUrl,
      createdAt: new Date(),
    });
    console.log('Story saved successfully');
  } catch (error) {
    console.error('Error saving story:', error);
    throw error;
  }
};

// Fetch favorite stories from Firestore
export const getFavoriteStories = async (user) => {
  try {
    const favoritesRef = collection(db, 'users', user.uid, 'favorites');
    const q = query(favoritesRef);
    const querySnapshot = await getDocs(q);

    const favorites = [];
    querySnapshot.forEach((doc) => {
      favorites.push({ id: doc.id, ...doc.data() });
    });
    return favorites;
  } catch (error) {
    console.error('Error fetching favorite stories:', error);
    throw error;
  }
};

export const deleteFavoriteStory = async (userId, storyId) => {
  try {
    // Reference to the specific favorite story document
    const storyRef = doc(db, 'users', userId, 'favorites', storyId);
    
    // Delete the document from Firestore
    await deleteDoc(storyRef);
    console.log('Favorite story deleted successfully');
  } catch (error) {
    console.error('Error deleting story:', error);
    throw new Error('Unable to delete favorite story.');
  }
};