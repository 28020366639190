import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import emailjs from 'emailjs-com';


const SERVICE_ID = 'service_jln4dll';
const TEMPLATE_ID_OWNER = 'template_79tp3in'; 
const TEMPLATE_ID_CUSTOMER = 'template_rajddkq'; 
const USER_ID = 'g0WvN75REBLq8HCm_';

const getFormattedDateTime = () => {
    const now = new Date();
    const date = now.toLocaleDateString(); 
    const time = now.toLocaleTimeString(); 
    return `${date} ${time}`;
};


// Load your Stripe publishable key
const stripePromise = loadStripe('pk_test_51Pw1QYDhFft9ggnY4iNNP0lkleNVQpgHlPeV41h6oSUmaNVB0xwBQIl7WXBxvODsirqFowuGsXvd6ThICzQX1Q0h00PDXNdHEI'); // Replace with your actual key

const OrderForm = ({ storyTitle, imageUrl, storyContent }) => {
    const [selectedProduct, setSelectedProduct] = useState('story');
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertType, setAlertType] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        address: '',
        city: '',
        state: '',
        zip: '',
    });

    
    const priceIds = {
        story: "price_1PwBIrDhFft9ggnYrF27eSYR", 
        plushie: 'price_1PwBJODhFft9ggnYcBT2I6KB', 
        both: "price_1PwBKiDhFft9ggnYmQJ7hasj", 
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);

        if (searchParams.has('success') || searchParams.has('cancel')) {
            if (searchParams.get('success')) {
                setAlertMessage('Payment Successful! Thank you for your order.');
                setAlertType('success');
                sendOrderEmail(); 
                sendCustomerReceipt(); 
            } else if (searchParams.get('cancel')) {
                setAlertMessage('Payment Cancelled. Please try again.');
                setAlertType('danger');
            }

            window.history.replaceState(null, '', window.location.pathname);
        }
    }, []);

    
    const sendOrderEmail = () => {
        const templateParams = {
            name: formData.name,
            email: formData.email,
            address: formData.address,
            city: formData.city,
            state: formData.state,
            zip: formData.zip,
            product: selectedProduct === 'story' ? 'Story Book' : selectedProduct === 'plushie' ? 'Plushie' : 'Both Story and Plushie',
            storyTitle: storyTitle,
            storyContent: storyContent,
            imageUrl: imageUrl,
            dateTime: getFormattedDateTime() 
        };
    
        emailjs.send(SERVICE_ID, TEMPLATE_ID_OWNER, templateParams, USER_ID)
            .then(response => {
                console.log('Order email sent successfully to the owner');
            })
            .catch(error => {
                console.error('Failed to send the order email:', error);
            });
    };

  
    const sendCustomerReceipt = () => {
        const templateParams = {
            name: formData.name,
            email: formData.email,
            product: selectedProduct === 'story' ? 'Story Book' : selectedProduct === 'plushie' ? 'Plushie' : 'Both Story and Plushie',
            storyTitle: storyTitle,
            total: calculateTotal().toFixed(2),
            dateTime: getFormattedDateTime() 
        };
    
        emailjs.send(SERVICE_ID, TEMPLATE_ID_CUSTOMER, templateParams, USER_ID)
            .then(response => {
                console.log('Receipt email sent successfully to the customer');
            })
            .catch(error => {
                console.error('Failed to send the receipt email:', error);
            });
    };
    const handleCheckout = async (e) => {
        e.preventDefault();
    
        const stripe = await stripePromise;
        const selectedPriceId = priceIds[selectedProduct];
    
        sendOrderEmail(); 
        sendCustomerReceipt(); 
    
      
        const { error } = await stripe.redirectToCheckout({
            lineItems: [
                {
                    price: selectedPriceId, 
                    quantity: 1,
                },
            ],
            mode: 'payment',
            successUrl: window.location.origin, 
            cancelUrl: window.location.origin, 
            customerEmail: formData.email, 
        });
    
        if (error) {
            setAlertMessage(error.message);
            setAlertType('danger');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

   
    const calculateTotal = () => {
        switch (selectedProduct) {
            case 'story':
                return 30.00;
            case 'plushie':
                return 50.00;
            case 'both':
                return 80.00;
            default:
                return 0;
        }
    };

    return (
        <div style={styles.container}>
            <h2>Love your child's story? So do we!</h2>
            <p style={styles.description}>
                Capture the magic with a keepsake they'll cherish forever. Order a physical copy of their personalized storybook or a custom plushie of their beloved character—or better yet, get both!
            </p>

            {/* Test Mode Warning */}
            <div style={styles.testModeWarning}>
                <strong>Note:</strong> This order form is currently in test mode and does not accept real orders. Please do not use real payment details.
            </div>

            {alertMessage && (
                <div className={`alert alert-${alertType}`} role="alert" style={styles.alert}>
                    {alertMessage}
                </div>
            )}

            <form onSubmit={handleCheckout}>
                <div style={styles.selectionContainer}>
                    <label style={styles.label}>
                        <input
                            type="radio"
                            value="story"
                            checked={selectedProduct === 'story'}
                            onChange={() => setSelectedProduct('story')}
                        />
                        Purchase a Physical Copy of the Story ($30)
                    </label>

                    <label style={styles.label}>
                        <input
                            type="radio"
                            value="plushie"
                            checked={selectedProduct === 'plushie'}
                            onChange={() => setSelectedProduct('plushie')}
                        />
                        Purchase a Custom Plushie of the Main Character ($50)
                    </label>

                    <label style={styles.label}>
                        <input
                            type="radio"
                            value="both"
                            checked={selectedProduct === 'both'}
                            onChange={() => setSelectedProduct('both')}
                        />
                        Purchase Both the Story and Plushie ($80)
                    </label>
                </div>

                <h3 style={styles.subHeader}>Enter Your Shipping Information:</h3>
                <div style={styles.formContainer}>
                    <input
                        type="text"
                        name="name"
                        placeholder="Full Name"
                        value={formData.name}
                        onChange={handleInputChange}
                        style={styles.input}
                        required
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleInputChange}
                        style={styles.input}
                        required
                    />
                    <input
                        type="text"
                        name="address"
                        placeholder="Shipping Address"
                        value={formData.address}
                        onChange={handleInputChange}
                        style={styles.input}
                        required
                    />
                    <input
                        type="text"
                        name="city"
                        placeholder="City"
                        value={formData.city}
                        onChange={handleInputChange}
                        style={styles.input}
                        required
                    />
                    <input
                        type="text"
                        name="state"
                        placeholder="State"
                        value={formData.state}
                        onChange={handleInputChange}
                        style={styles.input}
                        required
                    />
                    <input
                        type="text"
                        name="zip"
                        placeholder="ZIP Code"
                        value={formData.zip}
                        onChange={handleInputChange}
                        style={styles.input}
                        required
                    />
                </div>

                <div style={styles.total}>
                    <strong>Total: ${calculateTotal().toFixed(2)}</strong>
                </div>

                <button type="submit" style={styles.button}>
                    Submit Order
                </button>
            </form>
        </div>
    );
};


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        backgroundColor: '#f8f9fa',
        borderRadius: '10px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        maxWidth: '400px',
        margin: '0 auto',
    },
    description: {
        fontSize: '16px',
        color: '#333',
        margin: '20px 0',
        textAlign: 'center',
    },
    alert: {
        marginBottom: '20px',
        width: '100%',
    },
    testModeWarning: {
        backgroundColor: '#f8d7da',
        color: '#721c24',
        padding: '10px',
        marginBottom: '20px',
        borderRadius: '5px',
        textAlign: 'center',
        fontSize: '14px',
    },
    selectionContainer: {
        marginBottom: '20px',
    },
    subHeader: {
        fontSize: '1.2em',
        margin: '10px 0',
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        marginBottom: '20px',
        width: '100%',
    },
    label: {
        fontSize: '18px',
        color: '#4a4a4a',
        marginBottom: '10px',
        display: 'block',
    },
    input: {
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ddd',
        width: '100%',
    },
    total: {
        fontSize: '18px',
        marginBottom: '20px',
    },
    button: {
        padding: '10px 20px',
        borderRadius: '5px',
        backgroundColor: '#ff69b4',
        color: '#fff',
        fontSize: '16px',
        cursor: 'pointer',
        border: 'none',
    },
};

export default OrderForm;
