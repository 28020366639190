import React, { useState, useEffect, useRef } from "react";
import { saveFavoriteStory, getFavoriteStories } from "./FirestoreService";
import AWS from 'aws-sdk';
import PromptInput from "./PromptInput";
import StoryDisplay from "./StoryDisplay";
import FullStory from "./FullStory";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import AuthComponent from "./AuthComponent";
import { getCurrentUser, logout } from "./AuthService";
import { onAuthStateChanged } from "firebase/auth";
import "./styles.css";
import { auth } from "./FirebaseConfig";
import FavoriteStories from "./FavoriteStories";
import FavoriteStoriesList from "./FavoriteStoriesList";
import OrderForm from './OrderForm';
import logo from "../src/images/logo.jpeg"


AWS.config.update({
  accessKeyId: 'AKIAYQYUBHSWDCL5ET6M',
  secretAccessKey: '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',
  region: 'us-east-1'
});

const polly = new AWS.Polly();

const questions = {
  RealisticFiction: [
    "Who is the hero of our story?",
    "What kind of animal or creature is our hero?",
    "Where does our adventure take place?",
    "What does our hero love to do the most?",
    "Who is our hero's best friend, and what do they look like?",
    "What challenge does our hero face?",
    "How does our hero use their creativity to solve the challenge?",
    "What is our hero's favorite activity or treat?",
    "How does our story end with a big, happy celebration?",
  ],
  FairyTale: [
    "Who is the hero of our fairy tale?",
    "What magical creature is our hero?",
    "Where does our fairy tale take place?",
    "What is the magical ability of our hero?",
    "Who helps our hero on their quest?",
    "What magical challenge does our hero face?",
    "How does our hero use magic to solve the problem?",
    "What is the hero's favorite magical treat?",
    "How does our fairy tale end with a magical celebration?",
  ],
  Adventure: [
    "Who is the hero of our adventure?",
    "What brave animal is our hero?",
    "Where does our adventure take place?",
    "What is the hero's special skill?",
    "Who is the hero's adventurous friend?",
    "What dangerous challenge does the hero face?",
    "How does the hero's skill save the day?",
    "What treasure does the hero find?",
    "How does our adventure end with a heroic celebration?",
  ],
  Mystery: [
    "Who is the detective in our mystery?",
    "What clever animal is our detective?",
    "Where does the mystery take place?",
    "What is the detective's special skill?",
    "Who is the detective's sidekick?",
    "What mysterious challenge does the detective face?",
    "How does the detective solve the mystery?",
    "What clue leads to the solution?",
    "How does the mystery end with the case solved?",
  ],
  SciFi: [
    "Who is the hero of our space adventure?",
    "What futuristic creature is our hero?",
    "Where does our space adventure take place?",
    "What special gadget does our hero have?",
    "Who is the hero's space companion?",
    "What space challenge does the hero face?",
    "How does the hero use technology to win?",
    "What alien treat does the hero find?",
    "How does our space adventure end with a galactic celebration?",
  ],
  Fantasy: [
    "Who is the hero of our fantasy story?",
    "What mythical creature is our hero?",
    "Where does our fantasy story take place?",
    "What is the hero's magical power?",
    "Who helps the hero on their quest?",
    "What magical obstacle does the hero face?",
    "How does the hero use magic to overcome it?",
    "What enchanted item does the hero find?",
    "How does our fantasy story end with a magical celebration?",
  ],
};


const tones = [
  "Playful/Fun",
  "Rhyming/Whimsical",
  "Circular/Patterned",
  "Silly/Adventurous",
  "Gentle/Natural",
  "Dark/Whimsical",
  "Heartwarming/Relational",
];

const storyTypes = ["Realistic Fiction", "Fairy Tale", "Adventure", "Mystery", "Sci-Fi", "Fantasy"];



const randomAnswers = {
  RealisticFiction: {
    names: [
      "Benny", "Pip", "Sunny", "Ziggy", "Milo", "Lulu", "Dottie", "Boomer",
      "Pepper", "Jazz", "Scooter", "Bubbles", "Moxie", "Gizmo", "Tootie",
      "Sprout", "Pickle", "Whiskers", "Doodle", "Flick", "Nibbles", "Twinkle",
      "Waffles", "Buzz", "Chirpy", "Pipkin", "Wiggles"
    ],
    animals: [
      "curious rabbit", "playful puppy", "sneaky raccoon", "friendly fox",
      "wise old owl", "chatterbox parrot", "loyal dog", "gentle deer",
      "clever cat", "brave squirrel", "kind-hearted bear", "jumpy frog",
      "helpful beaver", "speedy hedgehog", "thoughtful tortoise"
    ],
    places: [
      "cozy woodland den", "sunny meadow full of flowers", "bustling forest neighborhood",
      "peaceful riverbank", "treehouse in the tallest tree", "little garden behind the big red barn",
      "hidden clearing in the woods", "countryside hill with a perfect view of the sunset",
      "small pond with lily pads", "grassy field where the wind always blows gently",
      "quiet library made of tree branches", "playful park near the edge of the woods",
      "warm sandy beach at the edge of a forest", "sun-dappled trail winding through the trees",
      "farmyard filled with friendly animals"
    ],
    activities: [
      "having a picnic by the river", "building a dam with the beavers", "playing hide and seek in the tall grass",
      "helping the birds build their nests", "teaching a young fox how to catch fish", "racing the squirrels up the trees",
      "finding the perfect spot to watch the sunset", "gathering berries for a feast", "taking care of a baby rabbit",
      "solving the mystery of the missing nuts", "going on a scavenger hunt in the woods",
      "helping the bees make honey", "organizing a talent show for all the animals",
      "building a sandcastle at the beach", "learning how to play a new game from the wise old owl"
    ],
    foods: [
      "freshly picked berries", "honey from the beehive", "crunchy apples", "nuts and seeds from the forest",
      "homemade bread from the farm", "juicy carrots", "sweet corn on the cob", "warm acorn muffins",
      "freshly baked berry pies", "wildflower honey", "cucumber sandwiches",
      "butterfly-shaped cookies", "carrot cake with cream cheese frosting",
      "peanut butter and jelly sandwiches", "apple slices dipped in honey"
    ],
    endings: [
      "with the animals gathering for a cozy campfire under the stars", "with everyone enjoying a peaceful evening watching fireflies",
      "with the friends building a new home for a family of birds", "with the characters finding a hidden garden full of wildflowers",
      "with the animals discovering a secret path that leads to a beautiful meadow", "with a big family dinner in the woodland clearing",
      "with the friends making a new tradition of stargazing every weekend", "with the animals working together to prepare for winter",
      "with everyone learning the importance of helping each other", "with the characters finding a treasure chest full of memories from their adventures"
    ],
    challenges: [
      "finding a safe place to store food for the winter", "helping a lost bird find its way home",
      "solving the mystery of the missing acorns", "fixing a hole in the dam before the water rises",
      "finding a new home for a family of rabbits", "teaching a young fox how to swim",
      "helping a bear who is stuck in a tree", "finding the perfect spot to build a new den",
      "helping a squirrel who has lost its way", "solving the puzzle of the hidden path through the forest"
    ]
  },
  FairyTale: {
    names: [
      "Glitter", "Twinkle", "Puff", "Zippy", "Wink", "Shimmer", "Tippy", "Fluffy",
      "Sparky", "Giggle", "Snuggle", "Twirl", "Poppy", "Breeze", "Snickerdoodle",
      "Puddle", "Doodle", "Skippy", "Flicker", "Fizzy", "Flutter", "Tizzy", "Pixie",
      "Glint", "Wisp", "Peaches", "Tickle", "Sprinkle"
    ],
    animals: [
      "glow-in-the-dark unicorn", "marshmallow dragon", "bubble-blowing frog", "crystal butterfly",
      "glittery griffin", "giggling goblin", "lollipop lion", "snowflake fairy",
      "rainbow-colored sloth", "peppermint panda", "moonlight fox", "starlight bunny",
      "sugarplum fairy", "diamond-eyed owl", "pillow-soft phoenix"
    ],
    places: [
      "chocolate river", "candy cane forest", "crystal palace", "mystical ice cream island",
      "rainbow waterfall", "enchanted jellybean mountain", "floating castle made of marshmallows",
      "bubblegum bridge", "sparkling crystal cave", "twinkling stardust field",
      "gingerbread house in the clouds", "glittering fairy village", "gummy bear lagoon",
      "cotton candy clouds", "magical popcorn meadow"
    ],
    activities: [
      "dancing with the stars", "bouncing on clouds", "painting the sky with rainbows",
      "singing with the moon", "catching shooting stars", "flying on the back of a butterfly",
      "exploring a castle made of candy", "diving into a pool of jellybeans",
      "skipping across lily pads made of marshmallows", "having a picnic on a flying carpet",
      "riding a dragon made of flowers", "making wishes with a genie",
      "floating in bubbles over the forest", "riding a unicorn through the stars",
      "building a tower of magical blocks"
    ],
    foods: [
      "sugar plum pie", "starlight cookies", "moonbeam muffins", "rainbow cake",
      "fairy floss pancakes", "crystal candy apples", "goblin grits", "dragon fruit smoothie",
      "unicorn popcorn", "enchanted chocolate drops", "cotton candy clouds",
      "magical marshmallow puffs", "glimmering gummy bears", "wizard's wand pretzels",
      "talking gingerbread cookies"
    ],
    endings: [
      "with the hero and their friends turning into shooting stars", "with the characters riding a rainbow to a new adventure",
      "with a magical creature granting everyone a special wish", "with the hero finding a secret door to a new fairyland",
      "with the hero turning into a prince/princess", "with the land being showered with sparkling rain",
      "with the characters turning into magical animals", "with the hero finding a never-ending storybook",
      "with a giant celebration where everyone gets their own castle", "with the characters floating away on a cloud to the next adventure"
    ],
    challenges: [
      "finding the lost key to the candy castle", "teaching a grumpy dragon to smile",
      "rescuing the moon from a bubble", "finding the missing pages of a magical book",
      "turning a stone statue back to life", "helping a shy fairy find her glow",
      "finding the way through a forest of mirrors", "solving the riddle of the talking tree",
      "catching the runaway stars", "unlocking the door to the hidden world"
    ]
  },
  Adventure: {
    names: [
      "Buzz", "Ace", "Rex", "Jet", "Trixie", "Dash", "Ripley", "Zara",
      "Scout", "Flash", "Nova", "Spike", "Rumble", "Skye", "Viper",
      "Talon", "Blaze", "Storm", "Blitz", "Indy", "Rocket", "Piper",
      "Clover", "Boomer", "Fury", "Shadow", "Dart", "Harley"
    ],
    animals: [
      "bionic kangaroo", "surfing platypus", "giant firefly", "electric eel",
      "talking toucan", "ninja raccoon", "laser-eyed tiger", "hoverboard-riding gorilla",
      "giant snail with rocket boosters", "time-traveling koala", "invisible cheetah",
      "super-strong octopus", "robotic penguin", "flying fox", "explorer monkey with a jetpack"
    ],
    places: [
      "volcano of candy", "underwater city made of coral", "floating island in the sky",
      "maze of giant puzzle pieces", "hidden temple of glittering jewels",
      "enchanted cave full of glowing crystals", "jungle where the trees tell stories",
      "desert filled with singing sand dunes", "mountain of endless stairs",
      "space station shaped like a giant star", "ocean of sparkling lemonade",
      "haunted pirate ship in a bottle", "forest where the trees are made of candy canes",
      "labyrinth of giant cheese blocks", "ancient ruins of a toy kingdom"
    ],
    activities: [
      "zip-lining across a canyon", "solving ancient riddles", "escaping from quicksand made of honey",
      "racing across a desert on a giant beetle", "skydiving into a jungle of giant flowers",
      "surfing on a river of lava", "exploring an underwater cave of treasures",
      "finding a hidden city made of gold", "climbing a mountain of marshmallows",
      "riding a tidal wave into a secret cave", "discovering a hidden world beneath the sand",
      "parachuting into a hidden valley", "diving for pearls in a magical ocean",
      "hiking through a jungle where the trees come alive", "racing through a tunnel of spinning lights"
    ],
    foods: [
      "spicy dragon wings", "glow-in-the-dark jelly", "crunchy beetle bites", "exploding candy rocks",
      "frosty mountain ice cream", "lava cake with extra lava", "giant fruit from the jungle of giants",
      "electric lemonade", "fizzing popcorn", "super-sweet honeycomb",
      "spicy cactus salsa", "chocolate-covered ants", "rainbow-colored trail mix",
      "bubblegum berries", "volcano pudding"
    ],
    endings: [
      "with the characters finding the legendary treasure of endless adventure", "with the heroes transforming into mythical creatures",
      "with the explorers discovering a new world hidden inside a volcano", "with the adventurers being knighted by the king of the jungle",
      "with the characters earning a medal of bravery from a mysterious guardian", "with the discovery of a secret map leading to another adventure",
      "with the heroes being hailed as the saviors of a forgotten kingdom", "with the adventurers finding a hidden doorway to a magical land",
      "with the characters rescuing a lost city and being celebrated as heroes", "with the explorers discovering they have magical powers"
    ],
    challenges: [
      "crossing a bridge made of spinning blades", "escaping a maze with invisible walls",
      "finding the lost city of glittering gold", "solving the riddle of the ancient sphinx",
      "outsmarting a gang of candy pirates", "finding the way out of the maze of mirrors",
      "escaping from the clutches of a giant monster", "crossing a river of lava",
      "solving the mystery of the enchanted compass", "surviving a sandstorm of glowing dust"
    ]
  },
  Mystery: {
    names: [
      "Whiskers", "Mittens", "Paws", "Snickers", "Twilight", "Echo", "Whisper",
      "Snoop", "Shadow", "Twister", "Silhouette", "Ghost", "Moon", "Haze",
      "Velvet", "Misty", "Fang", "Shiver", "Puzzle", "Riddle", "Sneak",
      "Rascal", "Flick", "Quirk", "Nudge", "Blip", "Trace", "Clue"
    ],
    animals: [
      "shape-shifting cat", "invisible owl", "ghostly bat", "talking raven",
      "moonlight wolf", "shadow fox", "whispering rat", "sleuthing squirrel",
      "phantom raccoon", "telepathic dog", "puzzle-solving parrot",
      "night-vision hamster", "mind-reading mouse", "sneaky ferret",
      "disappearing hedgehog"
    ],
    places: [
      "haunted library where books whisper", "abandoned toy factory", "secret passage behind a waterfall",
      "mysterious clock tower that only chimes at midnight", "foggy alley that leads to nowhere",
      "forgotten amusement park", "underground laboratory hidden in a forest",
      "ancient library with moving walls", "fog-covered island",
      "hidden attic full of forgotten treasures", "creaky old mansion with secret doors",
      "enchanted forest where the trees talk", "mystical lighthouse with a secret message",
      "old theater where the curtains never close", "cavern of endless echoes"
    ],
    activities: [
      "decoding an ancient message", "following footprints that disappear", "solving the riddle of the missing moon",
      "finding the hidden key to a secret door", "sneaking through a maze of shadows",
      "unlocking the secrets of a haunted mirror", "tracking a trail of glowing crumbs",
      "listening to whispers in the wind", "solving the mystery of the disappearing light",
      "outsmarting a trickster ghost", "finding the secret hidden inside a snow globe",
      "solving a puzzle box with endless layers", "following a trail of mysterious symbols",
      "solving the riddle of the talking clock", "deciphering a hidden message in the stars"
    ],
    foods: [
      "mystery-flavored lollipops", "invisible cookies", "whispering wands of cotton candy",
      "nightshade ice cream", "haunted popcorn", "glowing ghost gummies",
      "vanishing marshmallows", "shadow pies", "phantom fudge",
      "whispering chocolate bars", "riddle-filled jellybeans", "puzzle-shaped crackers",
      "moonlit cupcakes", "foggy frost bites", "secret-ingredient stew"
    ],
    endings: [
      "with the case being solved and the culprit turning into a friendly ghost", "with the detective discovering a hidden talent for magic",
      "with the mystery leading to a hidden world beneath the city", "with the culprit being a shadow who wanted a friend",
      "with the detective finding a portal to a world of puzzles", "with the characters being granted the ability to see the future",
      "with the case being solved but leaving behind a clue for another mystery", "with the culprit being a time-traveling cat",
      "with the detective finding a treasure chest full of stories", "with the mystery being solved and the characters becoming legends"
    ],
    challenges: [
      "deciphering a riddle that changes every time it's read", "catching a ghost that's only visible in the rain",
      "solving the mystery of the vanishing stars", "unlocking the secret of a cursed locket",
      "following a trail that disappears in the light", "finding a way out of the maze of whispers",
      "solving the riddle of the endless hallway", "catching a thief who can walk through walls",
      "finding a hidden room that only appears at midnight", "solving the puzzle of the silent clock"
    ]
  },
  SciFi: {
    names: [
      "Nova", "Zane", "Orion", "Luna", "Astro", "Xenon", "Quasar", "Zara",
      "Vega", "Zeke", "Stellar", "Cosmo", "Nebula", "Rocket", "Sparx",
      "Quantum", "Flicker", "Comet", "Galaxy", "Pulsar", "Rift",
      "Glimmer", "Echo", "Blip", "Pixel", "Zap", "Wavelength", "Nova"
    ],
    animals: [
      "hovering jellyfish", "laser-eyed cat", "flying robot dog", "glow-in-the-dark chameleon",
      "talking hologram hamster", "gravity-defying bird", "shape-shifting fox",
      "quantum squirrel", "plasma-powered turtle", "cybernetic wolf",
      "light-speed dragonfly", "zero-gravity owl", "robotic rabbit",
      "bio-luminescent bear", "crystal-powered ant"
    ],
    places: [
      "floating city in the clouds", "asteroid belt filled with hidden treasures", "quantum laboratory",
      "planet of floating islands", "cybernetic forest", "space station shaped like a giant star",
      "black hole amusement park", "galactic library", "spaceport on the edge of the universe",
      "ringworld of glowing crystals", "planet with a rainbow sky", "cyber-cavern full of ancient technology",
      "planet of talking robots", "interdimensional spaceship", "moon base made of glass"
    ],
    activities: [
      "racing through asteroid fields", "exploring a black hole", "finding the lost city of Atlantis on Mars",
      "navigating through a wormhole", "solving a galactic puzzle", "escaping a star about to go supernova",
      "building a robot out of stardust", "exploring an abandoned spaceship", "fighting space pirates",
      "searching for a lost planet", "discovering an alien civilization", "surfing on a solar flare",
      "traveling through time with a quantum watch", "communicating with an alien through music",
      "exploring a crystal cave on a distant moon"
    ],
    foods: [
      "cosmic cookies", "plasma pancakes", "gravity-free jelly", "zero-gravity marshmallows",
      "stardust cupcakes", "alien fruit smoothies", "time-traveling candy", "nebula noodles",
      "meteor meatballs", "lunar lemonade", "quantum-quenching drinks",
      "galaxy-flavored ice cream", "space spaghetti", "solar-powered soda", "glowing galactic gummies"
    ],
    endings: [
      "with the characters discovering a new galaxy", "with the explorers meeting their future selves",
      "with the heroes turning into stars", "with the characters finding a portal to another dimension",
      "with the galaxy being saved and a new era beginning", "with the discovery of a new form of life",
      "with the characters becoming legendary explorers", "with the adventurers finding a time machine",
      "with the characters building their own spaceship", "with the discovery of a hidden planet full of mysteries"
    ],
    challenges: [
      "escaping from a planet with shifting gravity", "solving the mystery of the disappearing stars",
      "outsmarting a time-traveling villain", "finding a way out of a black hole",
      "communicating with an alien race through music", "finding the key to an ancient spaceship",
      "solving the riddle of the quantum computer", "escaping from a collapsing star",
      "outsmarting a robot army", "finding a way back to their own time"
    ]
  },
  Fantasy: {
    names: [
      "Glitter", "Moonbeam", "Thistle", "Pippin", "Briar", "Fable", "Sorrel",
      "Lark", "Basil", "Rowan", "Ember", "Rune", "Willow", "Ash", "Hawthorn",
      "Sage", "Dewdrop", "Flicker", "Talon", "Fern", "Bracken",
      "Zephyr", "Clover", "Nettle", "Shade", "Echo", "Breeze", "Sprout"
    ],
    animals: [
      "gargantuan firefly", "flying dolphin", "ice-breathing dragon", "moonlit panther",
      "talking wolf", "whispering owl", "shimmering unicorn", "glow-in-the-dark rabbit",
      "crystal-eyed snake", "shadow fox", "phantom deer", "star-gazing griffin",
      "shape-shifting horse", "luminous jellyfish", "dancing pegasus"
    ],
    places: [
      "enchanted forest where the trees sing", "floating castle in the clouds", "moonlit meadow",
      "crystal cave filled with hidden treasures", "forgotten temple of ancient magic",
      "whispering woods where the shadows come alive", "enchanted waterfall that flows upwards",
      "hidden grove of talking animals", "haunted mansion with endless rooms",
      "valley of giant mushrooms", "forest of ever-changing paths", "cavern of glowing crystals",
      "sky island where the clouds are solid", "lake of liquid light", "city made of glass and shadows"
    ],
    activities: [
      "casting spells under a full moon", "riding a dragon across the stars", "solving the riddle of the enchanted mirror",
      "exploring a hidden temple of ancient magic", "flying with a flock of talking birds",
      "discovering a portal to another world", "making a wish in a magical fountain",
      "finding a lost city made of gold", "riding a unicorn through the clouds",
      "solving the mystery of the talking tree", "fighting off a horde of mischievous goblins",
      "swimming in a lake of liquid light", "finding a way out of the forest of illusions",
      "racing through a field of giant flowers", "taming a wild pegasus"
    ],
    foods: [
      "fairy dust pancakes", "phoenix feathers", "dragon fruit pie", "goblin brew",
      "magic mushroom soup", "enchanted apple cider", "giant's stew",
      "troll's cake", "unicorn cupcakes", "mermaid's seaweed salad",
      "griffin's grilled cheese", "wizard's wand pretzels", "potion popsicles",
      "witch's brew cookies", "knight's nutmeg tea"
    ],
    endings: [
      "with the hero finding the lost artifact of endless magic", "with the hero becoming the ruler of a hidden kingdom",
      "with the characters discovering their true magical powers", "with the hero finding a hidden portal to another world",
      "with the characters finding a spell book that writes new adventures", "with the hero being crowned the protector of the enchanted forest",
      "with the characters being granted a wish from the ancient gods", "with the hero finding a hidden city made of gold",
      "with the characters discovering a hidden land full of magical creatures", "with the hero returning home with the knowledge of all the world's magic"
    ],
    challenges: [
      "escaping from a labyrinth of ever-changing walls", "finding the way out of the enchanted forest",
      "solving the riddle of the talking mirror", "defeating a dragon that guards a hidden treasure",
      "breaking a curse that has trapped a friend in stone", "finding a way through the maze of illusions",
      "discovering the secret of the talking animals", "outsmarting a mischievous fairy",
      "defeating a horde of goblins", "solving the mystery of the glowing crystals"
    ]
  }
};


const questionToCategoryMap = {
  "Who is the hero of our story?": "names",
  "What kind of animal or creature is our hero?": "animals",
  "Where does our adventure take place?": "places",
  "What does our hero love to do the most?": "activities",
  "Who is our hero's best friend, and what do they look like?": "names",
  "What silly challenge does our hero face?": "challenges",
  "How does our hero use their creativity to solve the challenge?": "activities",
  "What is our hero's favorite sweet treat?": "foods",
  "How does our story end with a big, happy celebration?": "endings"
};


const getRandomElement = (arr) => arr[Math.floor(Math.random() * arr.length)];


const generateRandomAnswers = (storyType) => {
  const formattedStoryType = storyType.replace(/[^a-zA-Z0-9]/g, '');

  const answers = randomAnswers[formattedStoryType] || randomAnswers.RealisticFiction;

  return Object.keys(questionToCategoryMap).map((question) => {
    const category = questionToCategoryMap[question];
    return getRandomElement(answers[category]);
  });
};


const App = () => {
  const [answers, setAnswers] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showFullStory, setShowFullStory] = useState(false);
  const [story, setStory] = useState("");
  const [loading, setLoading] = useState(false);
  const [storyType, setStoryType] = useState("");
  const [selectedTone, setSelectedTone] = useState("");
  const [customAuthor, setCustomAuthor] = useState("");
  const [isReading, setIsReading] = useState(false);
  const [audioElements, setAudioElements] = useState([]);
  const [currentAudioIndex, setCurrentAudioIndex] = useState(0);
  const [mainCharacterImage, setMainCharacterImage] = useState("");
  const [user, setUser] = useState(null);
  const [favorites, setFavorites] = useState([]);
  const [viewFavorites, setViewFavorites] = useState(false);
  const [selectedStory, setSelectedStory] = useState(null);
  const [showOrderForm, setShowOrderForm] = useState(false);

  const getProgress = () => {
    const totalQuestions = getQuestions().length;
    return (currentQuestionIndex / totalQuestions) * 100;
  };

  const handleSelectStory = (story) => {
    setSelectedStory(story);
  };

  const saveStoryToFavorites = async () => {
    const storyName = prompt('Enter a name for this story:');
    if (!storyName) {
      alert('You must provide a name for the story.');
      return;
    }
    try {
      await saveFavoriteStory(user, story, mainCharacterImage, storyName);
      alert('Story saved to favorites!');
    } catch (error) {
      console.error('Error saving story to favorites:', error);
      alert('Failed to save the story. Please try again.');
    }
  };
  const loadFavorites = async () => {
    try {
      const savedFavorites = await getFavoriteStories();
      setFavorites(savedFavorites);
    } catch (error) {
      console.error("Error loading favorites: ", error);
    }
  };



  useEffect(() => {
    loadFavorites();
  }, []);

  const goToStoryCreation = () => {
    setViewFavorites(false);
    setSelectedStory(null);
    setStoryType('');
    setCurrentQuestionIndex(0);
    setShowFullStory(false);
  };


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);


  useEffect(() => {
    if (currentQuestionIndex >= getQuestions().length && answers.length > 0) {
      generateStory();
    }
  }, [currentQuestionIndex]);

  useEffect(() => {
    if (audioElements.length > 0 && currentAudioIndex < audioElements.length) {
      const audioElement = audioElements[currentAudioIndex];
      audioElement.play();
      audioElement.onended = () => {
        setCurrentAudioIndex((prevIndex) => prevIndex + 1);
      };
    } else {
      setIsReading(false);
    }
  }, [audioElements, currentAudioIndex]);

  const addAnswer = (answer) => {
    const newAnswers = [...answers];
    newAnswers[currentQuestionIndex] = answer;
    setAnswers(newAnswers);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const editAnswer = (index, newAnswer) => {
    const newAnswers = [...answers];
    newAnswers[index] = newAnswer;
    setAnswers(newAnswers);
  };

  const getQuestions = () => {
    const type = storyType.replace(/[^a-zA-Z0-9]/g, '');
    return questions[type] || questions.FairyTale;
  };

  const generateMainCharacterImage = async (storyPart) => {
    try {
      const contextForCharacter = storyPart.slice(0, 220);
      const modelslabApiKey = "QsNz9zxmrwj61RvMPsQeRcN8ZBzTn6cC64k9fcP0f4DZ94rEMpOZFlsJkYEV";
      const modelslabEndpoint = "https://modelslab.com/api/v6/realtime/text2img";
      const prompt = `Create a cartoon of the main character based on the following context: ${contextForCharacter} with big eyes, simple shapes, and bright colors. Make it whimsical and playful, avoiding realistic details. The main character should be alone, and the background should reflect the context`;

      const response = await fetch(modelslabEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key: modelslabApiKey,
          prompt,
          negative_prompt: "bad quality",
          width: 512,
          height: 512,
          safety_checker: false,
          seed: null,
          samples: 1,
          base64: false,
          webhook: null,
          track_id: null,
        }),
      });

      const data = await response.json();
      if (data.output && data.output[0]) {
        return data.output[0];
      } else {
        console.error("No image URL returned from Models Lab.");
        return null;
      }
    } catch (error) {
      console.error("Error generating main character image:", error);
      return null;
    }
  };

  const generateStory = async () => {
    const selectedQuestions = getQuestions();
    const toneName = selectedTone === "Custom Tone" ? customTone : selectedTone;

    const tonePrompts = {
      "Playful/Fun": `
            Write a playful and fun ${storyType} children's story, focusing on the characters' adventures and ending with a happy celebration. Start by introducing the main character in the first few lines, and then develop the story around their journey. Use vivid descriptions, a light-hearted tone, and simple language that is easy for children aged 5 to 10 to understand.`,
      "Rhyming/Whimsical": `
            Write a whimsical, rhyming ${storyType} children's story with playful, rhythmic language that includes made-up words, silly phrases, and repetition. Begin by introducing the main character right away, then build the story around their experiences. Ensure the language is fun and easy for children aged 5 to 10 to understand.`,
      "Circular/Patterned": `
            Write a circular ${storyType} children's story in a patterned style, where each event directly leads to the next in a humorous and engaging manner. Start the story by introducing the main character immediately, and ensure the language is simple and repetitive, easy for children aged 5 to 10 to follow. The story should come full circle, ending where it began.`,
      "Silly/Adventurous": `
            Write a silly and adventurous ${storyType} children's story with zany humor, funny dialogue, and unexpected twists. Introduce the main character right at the beginning and build the adventure around them. Use simple, easy-to-understand language that is suitable for children aged 5 to 10.`,
      "Gentle/Natural": `
            Write a simple, gentle ${storyType} children's story using short, rhythmic sentences and repetitive phrases. Introduce the main character in the first few lines, focusing on a natural setting or creatures, with simple language that is easy for children aged 5 to 10 to understand.`,
      "Dark/Whimsical": `
    Write a ${storyType} children's story that is both dark/eerie and whimsical, with complex themes and a poetic quality. Start the story by immediately introducing the main character and establishing their unique traits or challenges. Develop the narrative around their experiences, blending light-hearted whimsy with subtle elements of mystery or danger. Ensure the language and concepts are simple enough for children aged 5 to 10 to understand, while still maintaining the story's depth and enchanting atmosphere.`,
      "Heartwarming/Relational": `
            Write a heartwarming and gentle ${storyType} children's story with an emotionally driven narrative focused on relationships and gentle lessons. Introduce the main character immediately and build the story around their interactions. Use simple, easy-to-understand language appropriate for children aged 5 to 10.`,
    };

    const tonePrompt = tonePrompts[toneName] || tonePrompts["Playful/Fun"];

    const prompt = `
      ${tonePrompt}
      Here are the details for the story:
      ${selectedQuestions.map((q, i) => `${i + 1}. ${q}: ${answers[i]}`).join("\n")}
    `;

    setLoading(true);

    try {
      const response = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer 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`,
        },
        body: JSON.stringify({
          model: "gpt-4",
          messages: [{ role: "system", content: "You are a fun assistant that writes playful stories for children." }, { role: "user", content: prompt }],
          max_tokens: 2500,
          temperature: 0.7,
        }),
      });

      const data = await response.json();
      if (data.choices && data.choices[0].message.content) {
        const generatedStory = data.choices[0].message.content;
        setStory(generatedStory);
        const characterImage = await generateMainCharacterImage(generatedStory);
        setMainCharacterImage(characterImage);
        setShowFullStory(true);
      } else {
        setStory("Oops! I couldn't generate a story this time. Let's try again.");
        setShowFullStory(true);
      }
    } catch (error) {
      console.error("Error generating story:", error);
      setStory("An error occurred while generating the story. Please try again later.");
      setShowFullStory(true);
    } finally {
      setLoading(false);
    }
  };


  const readStoryAloud = async () => {
    setIsReading(true);

    const synthesizeStory = async (text) => {
      const textChunks = chunkText(text, 1000);
      const promises = textChunks.map(chunk => synthesizeChunk(chunk));
      return await Promise.all(promises);
    };

    const synthesizeChunk = (textChunk) => {
      const params = {
        Text: textChunk,
        OutputFormat: "mp3",
        VoiceId: "Ruth",
        Engine: "generative",
      };

      return new Promise((resolve, reject) => {
        polly.synthesizeSpeech(params, (err, data) => {
          if (err) {
            reject(err);
          } else if (data && data.AudioStream) {
            const audioBlob = new Blob([data.AudioStream], { type: 'audio/mp3' });
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result.split(',')[1]);
            };
            reader.readAsDataURL(audioBlob);
          }
        });
      });
    };

    try {
      const audioContents = await synthesizeStory(story);
      const audioElements = audioContents.map((audioContent) => {
        const audio = new Audio(`data:audio/mp3;base64,${audioContent}`);
        return audio;
      });

      setAudioElements(audioElements);
      setCurrentAudioIndex(0);
      playAudioElementsSequentially(audioElements);
    } catch (error) {
      console.error("Error synthesizing speech:", error);
    }
  };

  const chunkText = (text, maxSize) => {
    const chunks = [];
    let start = 0;

    while (start < text.length) {
      let end = start + maxSize;
      if (end < text.length) {
        while (end > start && text[end] !== ' ' && text[end] !== '\n') {
          end--;
        }
      }

      if (end === start) {
        end = start + maxSize;
      }

      chunks.push(text.substring(start, end).trim());
      start = end + 1;
    }

    return chunks;
  };

  const playAudioElementsSequentially = (audioElements) => {
    let currentIndex = 0;

    const playNextAudio = () => {
      if (currentIndex < audioElements.length) {
        const audioElement = audioElements[currentIndex];
        audioElement.play();


        audioElement.ontimeupdate = () => {
          const overlapTime = 0.5;
          if (audioElement.duration - audioElement.currentTime <= overlapTime) {
            audioElement.ontimeupdate = null;
            currentIndex++;
            playNextAudio();
          }
        };


        audioElement.onended = () => {
          audioElement.ontimeupdate = null;
          currentIndex++;
          playNextAudio();
        };
      }
    };

    playNextAudio();
  };

  const pauseReading = () => {
    if (audioElements[currentAudioIndex]) {
      audioElements[currentAudioIndex].pause();
    }
  };

  const resumeReading = () => {
    if (audioElements[currentAudioIndex]) {
      audioElements[currentAudioIndex].play();
    }
  };

  const stopReading = () => {
    if (audioElements[currentAudioIndex]) {
      audioElements[currentAudioIndex].pause();
      audioElements[currentAudioIndex].currentTime = 0;
    }
    setIsReading(false);
    setAudioElements([]);
    setCurrentAudioIndex(0);
  };

  const clearStory = () => {
    setAnswers([]);
    setCurrentQuestionIndex(0);
    setShowFullStory(false);
    setStory("");
    setStoryType("");
    setSelectedTone("");
    setMainCharacterImage("");
  };

  const handleLogout = () => {
    logout();
    setUser(null);
  };

  const downloadStory = async () => {
    if (!story) return;

    const zip = new JSZip();
    zip.file("story.txt", story);

    if (mainCharacterImage) {
      const response = await fetch(mainCharacterImage);
      const blob = await response.blob();
      zip.file("main_character_image.png", blob);
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "story_and_image.zip");
    });
  };

  const handleStoryTypeChange = (e) => {
    setStoryType(e.target.value);
  };


  const generateRandomStory = () => {
    if (!storyType) {
      alert("Please select a story type first!");
      return;
    }

    const randomStoryAnswers = generateRandomAnswers(storyType);
    setAnswers(randomStoryAnswers);
    setCurrentQuestionIndex(getQuestions().length);
  };
  const handleToneChange = (e) => {
    setSelectedTone(e.target.value);
  };


  

  return (
    <div className="app">
      <img src={logo} alt="TaleHatch Logo" className="app-logo" />
  
      <h1>TaleHatch</h1>
  
      {!user ? (
        <AuthComponent />
      ) : (
        <>
          <p>Welcome, {user.email}!</p>
  
          {!loading && (
            <button onClick={handleLogout}>Logout</button>
          )}
  
          {loading ? (
            <div className="loading-container">
              <img
                src="https://usagif.com/wp-content/uploads/gifs/book-48.gif"
                alt="Loading animation"
                className="loading-image"
              />
              <p className="loading-message">One moment while the magic happens...</p>
            </div>
          ) : (
            <>
              {!viewFavorites && !selectedStory && (
                <button onClick={() => setViewFavorites(true)}>View Favorites</button>
              )}
  
              {/* Display selected story or favorites list */}
              {viewFavorites && !selectedStory ? (
                <FavoriteStories
                  user={user}
                  onSelectStory={setSelectedStory}
                  goToStoryCreation={goToStoryCreation}
                />
              ) : selectedStory ? (
                <div>
                  <h2>{selectedStory.name}</h2>
                  <p>{selectedStory.story}</p>
                  {selectedStory.imageUrl && (
                    <img src={selectedStory.imageUrl} alt="Main Character" className="story-image" />
                  )}
                  <button onClick={() => setSelectedStory(null)}>Back to Favorites</button>
                  <button onClick={goToStoryCreation}>Create a New Story</button>
                </div>
              ) : (
                <>
                  {/* Step 1: Story Type Selection */}
                  {!storyType ? (
                    <div className="story-type-selection">
                      <h1>Step 1</h1>
                      <h2>Select the type of story you want to create:</h2>
                      <select onChange={(e) => setStoryType(e.target.value)} value={storyType}>
                        <option value="">Select a story type</option>
                        {storyTypes.map((type) => (
                          <option key={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    <>
                      {/* Step 2: Tone Selection */}
                      {!selectedTone ? (
                        <div className="tone-selection">
                          <h1>Step 2</h1>
                          <h2>Select a story tone:</h2>
                          <p>The tone you choose will define the mood and style of your story. Here’s a quick guide to each:</p>
  
                          <p><strong>Playful/Fun:</strong> Light and cheerful, ideal for a fun, adventurous story.</p>
                          <p><strong>Rhyming/Whimsical:</strong> A lyrical, playful style with a rhythmic flow.</p>
                          <p><strong>Circular/Patterned:</strong> A repetitive structure where events come full circle.</p>
                          <p><strong>Silly/Adventurous:</strong> Full of humor and wild twists, perfect for a zany journey.</p>
                          <p><strong>Gentle/Natural:</strong> Soft and calming, with a peaceful, nature-inspired feel.</p>
                          <p><strong>Dark/Whimsical:</strong> Magical with a hint of mystery and eerie charm.</p>
                          <p><strong>Heartwarming/Relational:</strong> Warm and emotional, focusing on meaningful relationships.</p>
  
                          <select onChange={handleToneChange} value={selectedTone}>
                            <option value="">Select a tone</option>
                            {tones.map((tone) => (
                              <option key={tone} value={tone}>
                                {tone}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : (
                        <>
                          {!showFullStory && (
                            <>
                              {/* Step 3: Question Input Form or Random Story Generation */}
                              <h1>Final Step</h1>
                              <div className="progress-container">
                                <progress max="100" value={getProgress()}></progress>
                                <p>Progress: {Math.floor(getProgress())}%</p>
                              </div>
                            </>
                          )}
  
                          {currentQuestionIndex < getQuestions().length && !showFullStory ? (
                            <>
                              <p>
                                Answer the questions below to craft your own personalized story, or say things like
                                <em> "Not sure yet" </em>, <em> "I don't know" </em>, etc., if you're unsure about a specific prompt.
                                If you're not feeling particularly creative at the moment, feel free to hit the
                                <strong> "Generate Random Story" </strong> button for a fun and delightful surprise!
                              </p>
  
                              <PromptInput
                                question={getQuestions()[currentQuestionIndex]}
                                addAnswer={(answer) => {
                                  setAnswers([...answers, answer]);
                                  setCurrentQuestionIndex(currentQuestionIndex + 1);
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <div>
                                <button onClick={readStoryAloud}>Read Story Aloud</button>
                                {isReading && (
                                  <div>
                                    <button onClick={pauseReading}>Pause</button>
                                    <button onClick={resumeReading}>Resume</button>
                                    <button onClick={stopReading}>Stop</button>
                                    <p>Reading will begin shortly...</p>
                                  </div>
                                )}
                              </div>
  
                              {/* Display notification that the story can be saved or ordered */}
                              <div style={{ marginBottom: '20px' }}>
                                <p>
                                  Love this story? You can save it to your favorites and come back to it any time.
                                  Or, if you're ready to bring the magic home, you can order a physical copy or a custom plushie right now!
                                </p>
                              </div>
  
                              {mainCharacterImage && (
                                <div className="character-image">
                                  <h3>Main Character</h3>
                                  <img src={mainCharacterImage} alt="Main Character" className="story-image" />
                                </div>
                              )}
  
                              <FullStory story={story} />
  
                              <button onClick={saveStoryToFavorites} disabled={!story || !mainCharacterImage}>
                                Save to Favorites
                              </button>
  
                              {/* Order Form Section */}
                              <div style={{ marginTop: '20px' }}>
                                <button onClick={() => setShowOrderForm(!showOrderForm)}>
                                  {showOrderForm ? 'Hide Order Form' : 'Order Story or Plushie'}
                                </button>
  
                                {showOrderForm && (
                                  <OrderForm
                                    storyTitle={storyType}
                                    imageUrl={mainCharacterImage}
                                    storyContent={story}
                                  />
                                )}
                              </div>
                            </>
                          )}
  
                          <StoryDisplay
                            answers={answers.slice(0, currentQuestionIndex)}
                            questions={getQuestions().slice(0, currentQuestionIndex)}
                            editAnswer={(index, newAnswer) => {
                              const updatedAnswers = [...answers];
                              updatedAnswers[index] = newAnswer;
                              setAnswers(updatedAnswers);
                            }}
                            showFullStory={showFullStory}
                          />
  
                          <button onClick={clearStory} disabled={answers.length === 0}>
                            Clear Story
                          </button>
  
                          {!showFullStory && storyType && (
                            <button onClick={generateRandomStory}>
                              Generate Random Story
                            </button>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
  
};

export default App;
