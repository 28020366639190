import React from "react";

const FullStory = ({ story }) => {
  const paragraphs = story
    .split("\n")
    .filter((paragraph) => paragraph.trim() !== "");

  return (
    <div className="full-story">
      <h2>Full Story:</h2>
      {paragraphs.map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
      ))}
    </div>
  );
};

export default FullStory;