import React, { useState } from 'react';

const StoryDisplay = ({ answers, questions, editAnswer, showFullStory }) => {
  const [editIndex, setEditIndex] = useState(null);
  const [editValue, setEditValue] = useState('');

  const handleEdit = (index) => {
    setEditIndex(index);
    setEditValue(answers[index]);
  };

  const handleSave = (index) => {
    editAnswer(index, editValue);
    setEditIndex(null);
  };

  return (
    <div className="story-display">
      <h2>Your Story Details:</h2>
      <ul>
        {questions.map((question, index) => (
          <li key={index} className="story-item">
            <strong>{question}</strong>:
            {editIndex === index ? (
              <>
                <input
                  type="text"
                  value={editValue}
                  onChange={(e) => setEditValue(e.target.value)}
                />
                <button className="edit-button" onClick={() => handleSave(index)}>Save</button>
              </>
            ) : (
              <>
                <span>{answers[index]}</span>
                {!showFullStory && (
                  <button className="edit-button" onClick={() => handleEdit(index)}>Edit</button>
                )}
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default StoryDisplay;